<template>
  <div class="box-wrap">
    <div class="header-box">
      <Search @changeCity="changeCity" @emit-search="searchClick" />
    </div>
    <div class="banner-box">
      <!-- <p class="title">选墓地 上如山选墓网</p>
      <p class="last-title">优选正规公墓，保障品质服务</p> -->
    </div>
    <div class="top-icons-box">
      <div class="icon-item" @click="showForm(false)">
        <img
          class="icon-img"
          src="@/assets/img/homerec/list-icon-1.png"
          alt=""
        />
        <p class="icon-text">选墓咨询</p>
      </div>
      <div class="icon-item" @click="showForm(false)">
        <img
          class="icon-img"
          src="@/assets/img/homerec/list-icon-2.png"
          alt=""
        />
        <p class="icon-text">折扣优惠</p>
      </div>
      <div class="icon-item" @click="showForm(false)">
        <img
          class="icon-img"
          src="@/assets/img/homerec/list-icon-3.png"
          alt=""
        />
        <p class="icon-text">殡仪服务</p>
      </div>
      <div class="icon-item" @click="showForm(false)">
        <img
          class="icon-img"
          src="@/assets/img/homerec/list-icon-4.png"
          alt=""
        />
        <p class="icon-text">骨灰寄存</p>
      </div>
    </div>
    <div class="nav-box">
      <div class="nav-item nav-item-1" @click="goPage('one')">
        <div class="top-title">
          <span>¥</span>
          <p>200</p>
          <span>现金</span>
        </div>
        <div class="btm-text">车油补，适合<span>自驾参观</span></div>
      </div>
      <div class="nav-item nav-item-2" @click="goPage('one')">
        <div class="top-title">
          <span>¥</span>
          <p>2000</p>
          <span>立减金</span>
        </div>
        <div class="btm-text">直接抵扣购买金额</div>
      </div>
      <div class="nav-item nav-item-3" @click="goPage('two')">
        <div class="nav-title">专车<span>免费</span>接送</div>
        <div class="last-title">可参观多家陵园</div>
        <div class="yy-text">预约专车<i></i></div>
      </div>
      <div class="nav-item nav-item-4" @click="goPage('three')">
        <div class="nav-title">购墓指导</div>
        <div class="last-title">专属顾问客服</div>
        <div class="yy-text">预约顾问<i></i></div>
      </div>
    </div>
    <div class="ly-list-box">
      <div class="list-title">
        <div class="title-l">
          <img src="@/assets/img/homerec/title-icon.png" alt="" />
          <h3>推荐陵园</h3>
        </div>
        <div class="title-r" @click="moreClick">
          <h5>查看更多</h5>
          <img src="@/assets/img/homerec/right-icon.png" alt="" />
        </div>
      </div>
      <div
        class="list-item"
        :class="{ 'border-bottom': index < lyList.length - 1 }"
        v-for="(item, index) of lyList"
        :key="'home_' + index"
        @click="goDetail(item)"
      >
        <div class="item-top">
          <img class="item-img" :src="item.listImages[0]" alt="" />
          <div class="item-info">
            <div class="info-top">
              <div class="name-text">{{ item.cemeteryName }}</div>
              <div class="regionName-text">{{ item.regionName }}</div>
            </div>
            <div class="info-price">
              <p class="price-text">
                <span>¥</span>{{ item.price | setPrice }}
              </p>
              <span class="wan">万</span>
              <i class="qi">起</i>
            </div>
            <div class="info-ld two-line">{{ item.description }}</div>
          </div>
        </div>
        <div class="item-btm">
          <div class="item-tags">
            <template v-for="(tag, tagIndex) of item.tag">
              <div
                v-if="tagIndex < 3"
                class="tags-item"
                :key="index + '-' + tagIndex"
              >
                {{ tag }}
              </div>
            </template>
          </div>
          <div class="item-contacts">
            <a class="tel-btn" @click.stop="telClick" href="tel:4008122133"
              >电话咨询</a
            >
            <div class="yy-btn" @click.stop="showForm(item)">预约看墓</div>
          </div>
        </div>
      </div>
      <div @click="moreClick" class="more-btn">查看更多陵园</div>
    </div>
    <div class="tjmx-box">
      <div class="list-title">
        <div class="title-l">
          <img src="@/assets/img/homerec/title-icon.png" alt="" />
          <h3>推荐墓型</h3>
        </div>
      </div>
      <div class="tjmx-item">
        <div
          class="tjmx-list"
          @click="showTJForm(item)"
          v-for="(item, index) of recList"
          :key="index"
        >
          <img class="tjmx-img" :src="item.recommendImage" alt="" />
          <div class="tjmx-title one-line">{{ item.tombName }}</div>
          <div class="tjmx-info">
            <span>参考价：</span><b>{{ item.referencePrice | setPrice }}</b
            ><i>万</i>
          </div>
        </div>
      </div>
    </div>
    <div class="zx-list-box">
      <div class="list-title">
        <div class="title-l">
          <img src="@/assets/img/homerec/title-icon.png" alt="" />
          <h3>推荐内容</h3>
        </div>
        <div class="title-r" @click="moreClickTwo">
          <h5>查看更多</h5>
          <img src="@/assets/img/homerec/right-icon.png" alt="" />
        </div>
      </div>
      <div
        class="zx-item"
        @click="goZxDetail(zx)"
        v-for="zx of zxList"
        :key="zx.id"
      >
        <div class="zx-left">
          <h3 class="zx-title two-line">{{ zx.title }}</h3>
          <div class="zx-info">
            <p>
              <img src="@/assets/img/homerec/zj.png" alt="" />{{
                zx.createTime | setTime
              }}
            </p>
            <img class="see-img" src="@/assets/img/homerec/see.png" alt="" />
            <span class="number-css">{{
              Number(zx.realReadAmount || 0) + Number(zx.baseReadAmount || 0)
            }}</span>
          </div>
          <div class="zx-tag">
            <span
              v-for="(tag, tagIndex) of zx.categorys"
              :key="'tag_' + tagIndex"
            >
              {{ tag }}
            </span>
          </div>
        </div>
        <div class="zx-right">
          <img :src="zx.coverImages[0]" alt="" />
        </div>
      </div>
      <div @click="moreClickTwo" class="more-btn">查看更多内容</div>
    </div>
    <div class="content-box">
      <div class="lc-content">
        <h3 class="title-text">购墓流程</h3>
        <div class="lc-img-list">
          <div class="lc-img-wrap">
            <img
              class="lc-img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-1_409016640756465749.png"
              alt=""
            />
            <p>预约咨询</p>
          </div>
          <div class="lc-img-icon">
            <img
              class="lc-icon-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-right_409016921233768454.png"
              alt=""
            />
          </div>
          <div class="lc-img-wrap">
            <img
              class="lc-img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-2_409016640777437192.png"
              alt=""
            />
            <p>专车接送</p>
          </div>
          <div class="lc-img-icon">
            <img
              class="lc-icon-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-right_409016921233768454.png"
              alt=""
            />
          </div>
          <div class="lc-img-wrap">
            <img
              class="lc-img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-3_409016640794214499.png"
              alt=""
            />
            <p>现场勘选</p>
          </div>
          <div class="lc-img-icon">
            <img
              class="lc-icon-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-right_409016921233768454.png"
              alt=""
            />
          </div>
          <div class="lc-img-wrap">
            <img
              class="lc-img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-4_409016921107939328.png"
              alt=""
            />
            <p>合同签署</p>
          </div>
        </div>
        <div class="lc-img-list last">
          <div class="lc-img-wrap">
            <img
              class="lc-img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-5_409016921103745078.png"
              alt=""
            />
            <p>墓碑制作</p>
          </div>
          <div class="lc-img-icon">
            <img
              class="lc-icon-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-right_409016921233768454.png"
              alt=""
            />
          </div>
          <div class="lc-img-wrap">
            <img
              class="lc-img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-6_409016921116327939.png"
              alt=""
            />
            <p>办理安葬</p>
          </div>
          <div class="lc-img-icon">
            <img
              class="lc-icon-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-right_409016921233768454.png"
              alt=""
            />
          </div>
          <div class="lc-img-wrap">
            <img
              class="lc-img-css"
              src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/lc-icon-7_409016921250545743.png"
              alt=""
            />
            <p>售后服务</p>
          </div>
        </div>
      </div>
    </div>
    <div class="wt-box">
      <h3 class="title-css">常见问题</h3>
      <div class="wt-list">
        <div class="wt-item">
          <img class="wt-icon" src="@/assets/img/homerec/wenhao.png" alt="" />
          <p class="wt-text">购买墓地，除了购墓费用外，还有哪些费用？</p>
          <div class="zx-btn" @click="goKf">
            <img class="zx-icon" src="@/assets/img/homerec/wx-zx.png" />
            <span>立即咨询</span>
          </div>
        </div>
        <div class="wt-item">
          <img class="wt-icon" src="@/assets/img/homerec/wenhao.png" alt="" />
          <p class="wt-text">{{ cityName }}哪些陵园是民政局批复的合法陵园？</p>
          <div class="zx-btn" @click="goKf">
            <img class="zx-icon" src="@/assets/img/homerec/wx-zx.png" />
            <span>立即咨询</span>
          </div>
        </div>
        <div class="wt-item">
          <img class="wt-icon" src="@/assets/img/homerec/wenhao.png" alt="" />
          <p class="wt-text">墓地是否有产权和期限，期限到期后怎么办？</p>
          <div class="zx-btn" @click="goKf">
            <img class="zx-icon" src="@/assets/img/homerec/wx-zx.png" />
            <span>立即咨询</span>
          </div>
        </div>
      </div>
    </div>
    <div class="b-a-box">
      <a class="beian" href="https://beian.miit.gov.cn/#/home"
        >蜀ICP备2021003234号</a
      >
      <p @click="goHome">成都众兴未来科技有限公司<span>公司简介</span></p>
    </div>
    <div class="kf-box">
      <div class="kf-btn" @click="goKf">在线咨询</div>
    </div>
    <div class="fixed-box" v-show="showGoTop">
      <a @click="telClick" href="tel:4008122133" class="fiexd-btn">
        <img src="@/assets/img/homerec/phone.png" alt="" />
        <div class="btn-item">
          <p>电话咨询</p>
          <span>4008 122 133</span>
        </div>
      </a>
      <div class="fiexd-btn" @click="showForm(false)">立即预约看墓</div>
    </div>
    <van-popup
      v-model="showModal"
      safe-area-inset-bottom
      position="bottom"
      closeable
      @close="closePopup"
    >
      <div class="form-wrap">
        <FormModal
          :showTj="tjActive"
          :tjData="tjData"
          :startTime="startTime"
          :code="lyCode"
        />
      </div>
    </van-popup>
  </div>
</template>
<script>
import {
  getPageListApi,
  collectPointApi,
  getSeeListApi,
} from "@/api/apiList.js";
import { mapMutations, mapGetters } from "vuex";
import { setChannel } from "@/util/setChannel.js";
export default {
  name: "homerec",
  components: {
    Search: () => import("@/components/search/index.vue"),
    FormModal: () => import("@/views/homerec/form.vue"),
  },
  filters: {
    setPrice(p) {
      let str = (p / 1000000 + "").split(".");
      if (str.length > 1) {
        str = str[0] + "." + str[1].slice(0, 2);
      } else {
        str = str[0] || 0 + ".00";
      }
      return str;
    },
    setTime(time) {
      let str = time.slice(0, 10);
      return str;
    },
  },
  data() {
    return {
      lyList: [],
      recList: [],
      code: "",
      recimg: "",
      showModal: false,
      collectPointParams: {},
      showGoTop: false,
      lyCode: "",
      startTime: 0,
      showFlag: false,
      zxList: [],
      cityCode: "",
      tjActive: false,
      tjData: null,
    };
  },
  computed: {
    ...mapGetters(["pages", "UUID", "cityName"]),
  },
  activated() {
    this.showModal = false;
    this.showFlag = false;
    this.startTime = +new Date();
    this.$nextTick(() => {
      this.showFlag = true;
    });
    setChannel(this);
  },
  deactivated() {},
  created() {
    setTimeout(() => {
      this.buryingPage(6);
    }, 1000);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true); // 这里加上true是为了保证浏览器滚动的及时性
  },
  methods: {
    ...mapMutations(["setSearch", "setCityCode"]),
    changeCity(code) {
      this.setCityCode(code);
      this.code = code;
      this.getPageListFun(code);
      this.getSeeListFun(code);
    },
    searchClick() {
      if (this.pages.channel && this.pages.subChannel) {
        this.$router.push(
          `/rec/search/${this.pages.channel}/${this.pages.subChannel}?code=${this.code}`
        );
      } else {
        this.$router.push(`/rec/search?code=${this.code}`);
      }
    },
    getPageListFun(cityCode) {
      let parms = {
        cityCode,
        current: 1,
        size: 10,
      };
      let toast = this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      getPageListApi(parms)
        .then((res) => {
          let data = res.data?.data;
          let list = data?.cemeteryPage?.records || [];
          this.lyList = list;
          this.recList = data.recommendedTombs || [];
          this.recimg = data?.recommendImage || "";
        })
        .finally(() => {
          toast.clear();
        });
    },
    goHome() {
      this.$router.push("/about");
    },
    goKf() {
      this.buryingPage(2);
      window.location.href =
        "https://work.weixin.qq.com/kfid/kfc51653415422b34fa";
    },
    goDetail(data) {
      if (this.pages.channel && this.pages.subChannel) {
        this.$router.push({
          path: `/rec/detail/${this.pages.channel}/${this.pages.subChannel}?code=${data.code}`,
          query: {
            title: data.cemeteryName,
          },
        });
      } else {
        this.$router.push({
          path: `/rec/detail?code=${data.code}`,
          query: {
            title: data.cemeteryName,
          },
        });
      }
    },
    goPage(pagetype) {
      if (this.pages.channel && this.pages.subChannel) {
        this.$router.push(
          `/rec/middle/${pagetype}/${this.pages.channel}/${this.pages.subChannel}`
        );
      } else {
        this.$router.push(`/rec/middle/${pagetype}`);
      }
    },
    showForm(data) {
      this.lyCode = data.code || "";
      this.showModal = true;
    },
    // 埋点
    buryingPage(type) {
      this.collectPointParams = {
        channelCode: this.pages.channel,
        subChannelCode: this.pages.subChannel,
        activityId: this.activityId,
        eventType: type,
        uuid: this.UUID,
        localUrl: location.href,
      };
      collectPointApi(this.collectPointParams)
        .then(() => {
          console.log("埋点成功");
        })
        .catch((err) => {
          console.log(err, "买点失败");
        });
    },
    // 监听滚动超过一屏
    handleScroll() {
      var scrolltop =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
      if (scrolltop >= 305) {
        // 浏览器窗口的内部高度 window.innerHeight
        this.showGoTop = true; // 超过就显示出来
      } else {
        this.showGoTop = false; // 不超过还藏起来
      }
    },
    telClick() {
      this.buryingPage(3);
    },
    moreClick() {
      if (this.pages.channel && this.pages.subChannel) {
        this.$router.push(
          `/rec/search/${this.pages.channel}/${this.pages.subChannel}?code=${this.code}`
        );
      } else {
        this.$router.push(`/rec/search?code=${this.code}`);
      }
    },
    moreClickTwo() {
      if (this.pages.channel && this.pages.subChannel) {
        this.$router.push(
          `/rec/infolist/${this.pages.channel}/${this.pages.subChannel}?citycode=${this.cityCode}`
        );
      } else {
        this.$router.push(`/rec/infolist?citycode=${this.cityCode}`);
      }
    },
    closePopup() {
      this.lyCode = "";
      this.tjActive = false;
    },
    getSeeListFun(code) {
      getSeeListApi({
        cityCode: code,
        current: 1,
        size: 6,
        category: -1
      }).then((res) => {
        this.zxList = res.data.data?.records || [];
      });
    },
    goZxDetail(item) {
      if (this.pages.channel && this.pages.subChannel) {
        this.$router.push(
          `/rec/infodetail/${this.pages.channel}/${this.pages.subChannel}?id=${item.id}`
        );
      } else {
        this.$router.push(`/rec/infodetail?id=${item.id}`);
      }
    },
    showTJForm(data) {
      let params = new URLSearchParams();
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          const value = data[key];
          if(value) {
            params.append(key, value)
          }
        }
      }
      let paramsStr = params.toString()
      console.log(paramsStr,'paramsStr');
      try {
          if (this.pages.channel && this.pages.subChannel) {
        this.$router.push({
          path: `/rec/mxdetail/${this.pages.channel}/${this.pages.subChannel}/?${paramsStr}`
        });
      } else {
        this.$router.push(`/rec/mxdetail?${paramsStr}`);
      }
      } catch (error) {
        console.log(error,'errorerrorerror');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin flex {
  display: flex;
  align-items: center;
}
i {
  font-style: normal
}
.box-wrap {
  background: #fff;
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: calc(1.42rem + env(safe-area-inset-bottom));
  overflow-y: auto;
  .header-box {
    padding: 0.24rem;
  }
  .banner-box {
    height: 3.7rem;
    background: url("~@/assets/img/homerec/banner.png") no-repeat center;
    background-size: 100%;
    box-sizing: border-box;
    padding-top: 0.78rem;
    padding-left: 0.28rem;
    .title {
      font-size: 0.48rem;
      font-family: "PangMenZhengDao";
      font-weight: normal;
      color: #ffffff;
      line-height: 0.52rem;
      // font-weight: bold;
      background: linear-gradient(180deg, #fff1c7 0%, #fed493 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .last-title {
      font-size: 0.32rem;
      font-weight: 400;
      color: #ffe1ab;
      line-height: 0.42rem;
      padding-top: 0.2rem;
    }
  }
  .top-icons-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0.36rem 0;
    .icon-item {
      display: flex;
      align-items: center;
      flex-direction: column;
      .icon-img {
        width: 0.9rem;
        height: 0.9rem;
      }
      .icon-text {
        font-size: 0.28rem;
        font-weight: 400;
        color: #333333;
        line-height: 0.4rem;
        padding-top: 0.08rem;
      }
    }
  }
  .nav-box {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0.24rem;
    .nav-item {
      display: flex;
      flex-direction: column;
      // align-items: center;
      width: 3.42rem;
      height: 1.38rem;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 0.17rem;
      margin-bottom: 0.17rem;
      &:nth-of-type(2n) {
        margin-right: 0;
      }
      &:nth-of-type(n + 3) {
        margin-bottom: 0;
        padding: 0.2rem 0 0 0.26rem;
        box-sizing: border-box;
        height: 1.56rem;
      }
      &.nav-item-1,
      &.nav-item-2 {
        background-image: url(~@/assets/img/homerec/nav-bg-1.png);
      }
      &.nav-item-3 {
        background-image: url(~@/assets/img/homerec/nav-bg-2.png);
      }
      &.nav-item-4 {
        background-image: url(~@/assets/img/homerec/nav-bg-3.png);
      }
      .top-title {
        display: flex;
        align-items: baseline;
        color: #9f5f37;
        padding-top: 0.25rem;
        width: 80%;
        justify-content: center;
        span {
          font-size: 0.24rem;
          font-weight: 500;
          color: #995d36;
          line-height: 0.28rem;
        }
        p {
          font-weight: 500;
          line-height: 0.48rem;
          font-size: 0.42rem;
          font-weight: bold;
        }
      }
      .btm-text {
        width: 80%;
        font-size: 0.22rem;
        font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
        font-weight: 400;
        color: #995d36;
        line-height: 0.28rem;
        text-align: center;
        span {
          color: #9f5f37;
          font-weight: bold;
        }
      }
      .nav-title {
        font-size: 0.28rem;
        line-height: 0.32rem;
        color: #333333;
        font-weight: 600;
        span {
          color: #951e15;
        }
      }
      .last-title {
        font-size: 0.26rem;
        line-height: 0.32rem;
        color: #666666;
        padding-top: 0.1rem;
      }
      .yy-text {
        display: flex;
        align-items: center;
        font-size: 0.26rem;
        color: #951e15;
        line-height: 0.32rem;
        padding-top: 0.1rem;
        i {
          width: 0.26rem;
          height: 0.26rem;
          background: url("~@/assets/img/homerec/nav-icon-right.png") no-repeat
            center;
          background-size: 0.24rem 0.24rem;
          margin-left: 0.06rem;
          transform: translateY(-1px);
        }
      }
    }
  }
  .ly-list-box {
    margin-top: 0.3rem;
    padding: 0.24rem;

    .list-item {
      padding: 0.38rem 0 0.3rem;
      .item-top {
        display: flex;
        .item-img {
          width: 2.85rem;
          height: 1.9rem;
          border-radius: 0.1rem;
        }
        .item-info {
          width: 0;
          flex: 1;
          padding-left: 0.24rem;
          .info-top {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            .name-text {
              font-size: 0.32rem;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
              line-height: 0.36rem;
              width: 0;
              flex: 1;
            }
            .regionName-text {
              font-size: 0.28rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
            }
          }
          .info-price {
            display: flex;
            align-items: baseline;
            padding-top: 0.2rem;
            .wan,
            .qi {
              font-size: 0.24rem;
              color: #999999;
            }
            .wan {
              color: #951e15;
            }
            .price-text {
              font-size: 0.32rem;
              font-family: PingFangSC-Regular, PingFang SC;
              color: #951e15;
              line-height: 0.28rem;
              font-weight: bold;
              span {
                font-size: 0.24rem;
                font-weight: 400;
              }
            }
          }
          .info-ld {
            padding-top: 0.16rem;
            font-size: 0.28rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 0.4rem;
          }
        }
      }
      .item-btm {
        @include flex();
        padding-top: 0.12rem;
        .item-tags {
          @include flex();
          flex-wrap: wrap;
          width: 0;
          flex: 1;
          margin-right: 0.2rem;
          .tags-item {
            width: 1.2rem;
            height: 0.4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff2e4;
            border-radius: 0.08rem;
            font-size: 0.24rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ff9d20;
            margin-right: 0.06rem;
            &:last-of-type {
              margin: 0;
            }
          }
        }
        .item-contacts {
          flex: none;
          @include flex();
          .tel-btn {
            @include flex();
            width: 1.44rem;
            height: 0.48rem;
            border-radius: 0.28rem;
            border: 1px solid #951e15;
            justify-content: center;
            font-size: 0.28rem;
            font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
            font-weight: 400;
            color: #951e15;
          }
          .yy-btn {
            margin-left: 0.12rem;
            @include flex();
            justify-content: center;
            width: 1.44rem;
            height: 0.48rem;
            background: #ff9d20;
            border-radius: 0.28rem;
            border: 1px solid #ff9d20;
            font-size: 0.28rem;
            font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
    }
  }
  .more-btn {
    @include flex();
    justify-content: center;
    margin: 0.16rem auto 0;
    width: 2.86rem;
    height: 0.6rem;
    border-radius: 0.32rem;
    border: 1px solid #ff9d20;
    font-size: 0.28rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff9d20;
  }
  .list-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title-l {
      @include flex();
      img {
        width: 0.42rem;
        height: 0.28rem;
      }
      h3 {
        padding-left: 0.12rem;
        font-size: 0.42rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 0.42rem;
      }
    }
    .title-r {
      @include flex();
      h5 {
        font-size: 0.28rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 0.4rem;
        padding-right: 0.08rem;
      }
      img {
        width: 0.12rem;
        height: 0.24rem;
      }
    }
  }
  .tjmx-box {
    padding: 0.24rem;
    padding-top: 0.58rem;
    .tjmx-item {
      padding-top: 0.36rem;
      display: flex;
      flex-wrap: wrap;
      .tjmx-list {
        display: flex;
        flex-direction: column;
        width: 3.42rem;
        margin-right: 0.17rem;
        margin-bottom: 0.36rem;
        &:nth-of-type(2n) {
          margin-right: 0;
        }
        .tjmx-img {
          width: 100%;
          height: 2.4rem;
          border-radius: 0.1rem;
        }
        .tjmx-title {
          padding-top: 0.2rem;
          font-size: 0.32rem;
          font-family: Alibaba PuHuiTi 2-65 Medium, Alibaba PuHuiTi 20;
          font-weight: 500;
          color: #333333;
          line-height: 0.34rem;
        }
        .tjmx-info {
          display: flex;
          align-items: baseline;
          padding-top: 0.2rem;
          span,
          i {
            font-size: 0.24rem;
            font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
            font-weight: 400;
            color: #999999;
          }
          b {
            font-size: 0.34rem;
            font-weight: bold;
            font-family: Alibaba PuHuiTi 2-85 Bold;
            color: #951e15;
          }
          i {
            color: #951e15;
          }
        }
      }
    }
  }
  .zx-list-box {
    padding: 0.6rem 0.24rem;
    background: #f7f7f7;
    .list-title {
      padding-bottom: 0.32rem;
    }
    .zx-item {
      padding: 0.2rem;
      background: #fff;
      border-radius: 0.12rem;
      margin-bottom: 0.2rem;
      padding-bottom: 0;
      display: flex;
      justify-content: space-between;
      .zx-left {
        .zx-title {
          font-size: 0.32rem;
          font-family: Alibaba PuHuiTi 2-65 Medium, Alibaba PuHuiTi 20;
          font-weight: 500;
          color: #333333;
          line-height: 0.38rem;
          height: 0.76rem;
        }
        .zx-info {
          display: flex;
          align-items: center;
          padding-top: 0.12rem;
          p {
            display: flex;
            align-items: center;
            font-size: 0.24rem;
            font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
            font-weight: 400;
            color: #999999;
            line-height: 0.38rem;
            img {
              width: 0.18rem;
              height: 0.18rem;
              transform: translateY(-0.02rem);
              margin-right: 0.04rem;
            }
          }
          .see-img {
            width: 0.26rem;
            margin-left: 0.56rem;
          }
          .number-css {
            font-size: 0.24rem;
            font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
            font-weight: 400;
            color: #999999;
            line-height: 0.38rem;
            padding-left: 0.04rem;
          }
        }
        .zx-tag {
          display: flex;
          flex-wrap: wrap;
          padding-top: 0.12rem;
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.2rem;
            height: 0.4rem;
            border-radius: 0.08rem;
            border: 1px solid #ffb251;
            font-size: 0.24rem;
            font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
            font-weight: 400;
            color: #ff9d20;
            line-height: 1;
            margin-right: 0.12rem;
            margin-bottom: 0.12rem;
          }
        }
      }
      .zx-right {
        width: 2.54rem;
        height: 1.78rem;
        padding-left: 0.2rem;
        flex: none;
        img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 0.1rem;
        }
      }
    }
    .more-btn {
      margin-top: 0.48rem;
    }
  }
  .content-box {
    padding: 0.5rem 0.24rem;
    .lc-content {
      padding: 0.36rem 0.5rem 0.5rem;
      background: linear-gradient(
        180deg,
        rgba(246, 217, 193, 0.12) 0%,
        rgba(211, 167, 126, 0.12) 100%
      );
      // opacity: 0.12;
      display: flex;
      align-items: center;
      flex-direction: column;
      .title-text {
        font-size: 0.42rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 0.6rem;
      }
      .lc-img-list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 0.36rem;
        .lc-img-wrap {
          display: flex;
          align-items: center;
          flex-direction: column;
          .lc-img-css {
            width: 0.8rem;
            height: 0.8rem;
          }
          p {
            font-size: 0.26rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #6c340d;
            line-height: 0.38rem;
            padding-top: 0.09rem;
          }
        }
        .lc-icon-css {
          display: block;
          width: 0.34rem;
          margin-top: 0.3rem;
        }
      }
      .last.lc-img-list {
        justify-content: center;
        .lc-img-icon {
          margin: 0 0.28rem;
        }
      }
    }
  }
  .wt-box {
    .title-css {
      font-size: 0.42rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 0.6rem;
      text-align: center;
    }
    .wt-list {
      padding: 0.24rem;
      .wt-item {
        display: flex;
        padding-bottom: 0.32rem;
        .wt-icon {
          flex: none;
          width: 0.32rem;
          height: 0.32rem;
        }
        .wt-text {
          padding: 0 0.12rem;
          flex: 1;
          width: 0;
          font-size: 0.3rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 0.42rem;
        }
        .zx-btn {
          @include flex();
          justify-content: center;
          width: 1.62rem;
          height: 0.48rem;
          flex: none;
          border-radius: 0.24rem;
          border: 1px solid #ff9d20;
          .zx-icon {
            width: 0.26rem;
            height: 0.22rem;
          }
          span {
            padding-left: 0.1rem;
            font-size: 0.24rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ff9d20;
          }
        }
      }
    }
  }
  .b-a-box {
    padding: 0.24rem 0;
    background: #f7f7f7;
    text-align: center;
    p {
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #c1c1c1;
      line-height: 0.36rem;
      span {
        color: #ff9d20;
        text-decoration: underline;
      }
    }
  }
  .kf-box {
    position: fixed;
    right: 0;
    position: fixed;
    top: 45vh;
    width: 1.46rem;
    height: 1.56rem;
    background: url("~@/assets/img/homerec/kf-icon.png") no-repeat center top;
    background-size: 1.46rem 1.56rem;
    padding-top: 1rem;
    box-sizing: border-box;
    transform: translateY(-50%);
    .kf-btn {
      width: 1.12rem;
      height: 0.4rem;
      margin: 0 auto;
      background: #ff9d20;
      box-shadow: 0px 0.04rem 0.16rem 0px rgba(172, 118, 51, 0.35);
      border-radius: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.22rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 0.34rem;
    }
  }
  .fixed-box {
    position: fixed;
    left: 50%;
    width: 7.5rem;
    // height: 1.36rem;
    bottom: 0;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0.24rem 0.32rem;
    box-shadow: 0px -0.02rem 0.04rem 0px rgba(0, 0, 0, 0.07);
    background: #fff;
    padding-bottom: calc(0.32rem + env(safe-area-inset-bottom));
    z-index: 9;
    .fiexd-btn {
      width: 3.3rem;
      height: 0.8rem;
      border-radius: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &:first-child {
        border: 1px solid #951e15;
        img {
          width: 0.44rem;
          height: 0.4rem;
        }
        .btn-item {
          color: #951e15;
          padding-left: 0.12rem;
          p {
            font-size: 0.32rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            line-height: 0.32rem;
          }
          span {
            font-size: 0.24rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 0.28rem;
          }
        }
      }
      &:last-child {
        font-size: 0.32rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        background: #ff9d20;
      }
    }
  }
}
.form-wrap {
  padding: 0.5rem;
}
</style>